import { api } from './api'

export default {
    addApiKey() {
        return api.post('/users/api-keys')
    },

    updateApiKey(payload) {
        return api.put(`/users/api-keys/${payload.id}`, payload.data)
    },

    deleteApiKey(payload) {
        return api.delete(`/users/api-keys/${payload.id}`)
    },

    listApiKeys(payload) {
        return api.get(`/users/${payload.id}/api-keys`)
    },

    getVersion() {
        return api.get('/version')
    }
}
