// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/svg/icons/key.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/svg/icons/book.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("@/assets/svg/icons/book-open.svg");
var ___CSS_LOADER_URL_IMPORT_3___ = require("@/assets/svg/icons/plus.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
exports.push([module.id, ".api-view .expand-component__inner .button-component:not(:last-child){margin-right:15px}.api-view .trash-component{margin-left:15px}.api-view>.card-component .card-component__header{padding:10px 20px}.api-view>.card-component .card-component__footer{padding:15px 20px}.api-view .card-component--actions .card-component__content{padding:8px}.api-view p{margin-bottom:20px}.api-view strong{font-weight:500}.api-view .tag-component{margin-left:10px}.api-view .input-component__prepend{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin:0 0 2px 12px}.api-view .input-component__icon--key{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:50%;background-repeat:no-repeat;display:inline-block;width:16px;height:16px;background-size:contain}.api-view .button-component__icon--book{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-position:50%;background-repeat:no-repeat}.api-view .button-component__icon--book-open{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");background-position:50%;background-repeat:no-repeat}.api-view .button-component--add{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;width:100%;padding:11px 16px;border-radius:4px}.api-view .button-component--add:after{content:\"\";-webkit-box-flex:1;-ms-flex:1;flex:1}.api-view .button-component--add__icon{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");background-position:50%;background-repeat:no-repeat;display:inline-block;-webkit-box-flex:1;-ms-flex:1;flex:1;width:20px;height:20px;background-position:0!important;background-size:contain}", ""]);
// Exports
module.exports = exports;
