<template>
    <div class="api-view">
        <ExpandComponent :expandable="hasApiKey">
            <template #header>
                <RowComponent align="center">
                    <span>What is an API-key?</span>
                </RowComponent>
            </template>
            <p>
                API-key is a type of access credential that a developer would need to be able to
                integrate an app/service that s/he is developing with your SurveyLegend account.
                API-keys are used like passwords, to provide other apps/services access to the
                SurveyLegend API. You can generate an API-key here. Keep in mind that the API-key is
                not bound to any specific questionnaire, it is tied to your entire account.
            </p>
            <p>
                <strong>Note:</strong> Your API-key carries privileges, make sure you keep it
                secure! Do not share your secret API-keys in publicly accessible areas such as
                GitHub, client-side code and so forth.
            </p>
            <RowComponent align="center">
                <ButtonComponent
                    class="button-component--icon"
                    type="primary"
                    shadow
                    @click="handleLearn"
                >
                    <i class="button-component__icon button-component__icon--book" />
                    <span>User guide</span>
                </ButtonComponent>

                <ButtonComponent type="primary" shadow @click="handleDocs">
                    <i class="button-component__icon button-component__icon--book-open" />
                    <span>API docs</span>
                </ButtonComponent>
            </RowComponent>
        </ExpandComponent>
        <CardComponent v-if="!apiKey" class="card-component--actions">
            <ButtonComponent
                class="button-component--add"
                type="success"
                shadow
                @click="handleGenerate"
            >
                <i class="button-component--add__icon" />
                <span>Generate an API-key</span></ButtonComponent
            >
        </CardComponent>
        <CardComponent v-else>
            <template #header>
                <RowComponent justify="space-between" align="center">
                    <RowComponent align="center">
                        <HelpComponent
                            markdown="api-version"
                            external-link="https://www.surveylegend.com/user-guide/api/#api-version"
                        />
                        <span>API</span>
                        <TagComponent>v{{ apiVersion }}</TagComponent>
                    </RowComponent>
                    <TrashComponent @click="handleDelete(apiKey)">Delete</TrashComponent>
                </RowComponent>
            </template>
            <RowComponent justify="space-between" align="center">
                <RowComponent align="center">
                    <HelpComponent
                        markdown="api-key"
                        external-link="https://www.surveylegend.com/user-guide/api/#api-key"
                    />
                    <span>API-key</span>
                </RowComponent>
                <InputComponent :value="apiKey.apiKey" readonly @click="$refs.apiKey.onClick()">
                    <template #prepend>
                        <i class="input-component__icon--key" />
                    </template>
                    <template #append>
                        <CopyComponent ref="apiKey" :value="apiKey.apiKey" />
                    </template>
                </InputComponent>
            </RowComponent>
            <template #footer>
                <RowComponent justify="space-between" align="center">
                    <RowComponent align="center">
                        <HelpComponent
                            markdown="activate-api"
                            external-link="https://www.surveylegend.com/user-guide/api/#activate-api-key"
                        />
                        <span>Activate</span>
                    </RowComponent>
                    <RowComponent justify="end" align="center">
                        <ToggleComponent
                            v-model="apiKey.active"
                            @change="handleChange($event, apiKey)"
                        />
                    </RowComponent>
                </RowComponent>
            </template>
        </CardComponent>

        <Teleport to="body">
            <UpgradeComponent
                ref="upgrade-create-api-keys"
                name="create-api-keys"
                image="create-api-keys.png"
            />
            <WarningComponent
                ref="warning-delete-api-key"
                name="delete-api-key"
                image="move-survey-to-trash.png"
                word="delete"
            />
        </Teleport>
    </div>
</template>

<script>
import ApiKeyService from '@/services/api-key-service'
import { mapGetters } from 'vuex'

export default {
    name: 'API',

    metaInfo: {
        title: 'API'
    },

    data() {
        return {
            apiKey: '',
            apiVersion: ''
        }
    },

    computed: {
        ...mapGetters({
            encodedUserId: 'auth/encodedUserId',
            permissions: 'auth/permissions'
        }),

        hasApiKey() {
            return !!this.apiKey
        },

        hasPermission() {
            return this.permissions['create-api-keys']
        }
    },

    async mounted() {
        if (this.hasPermission) {
            const { data } = await ApiKeyService.listApiKeys({ id: this.encodedUserId })

            if (data[0]) {
                this.apiKey = {
                    apiKey: data[0].apiKey,
                    active: Boolean(data[0].active)
                }
            }
        }

        const {
            data: { version }
        } = await ApiKeyService.getVersion()

        this.apiVersion = version
    },

    methods: {
        handleLearn() {
            window.open('https://www.surveylegend.com/user-guide/api/#api-keys')
        },

        async handleGenerate() {
            const permission = 'create-api-keys'

            if (!this.hasPermission) {
                this.track('open-upgrade-modal', permission)

                const isConfirmed = await this.$refs[`upgrade-${permission}`].open()

                this.$refs[`upgrade-${permission}`].close()

                if (isConfirmed)
                    window.location.href = `https://www.surveylegend.com/upgrade?source=upgrade_modal&type=${permission}&level=${this.permissions.level}`

                return
            } else if (permission) {
                this.track('has-permission', permission)
            }

            const { data } = await ApiKeyService.addApiKey()

            this.apiKey = {
                apiKey: data.apiKey,
                active: Boolean(data.active)
            }
        },

        handleDocs() {
            window.open('https://api.surveylegend.com/docs/')
        },

        async handleDelete({ apiKey }) {
            const isConfirmed = await this.$refs['warning-delete-api-key'].open()

            this.$refs['warning-delete-api-key'].close()

            if (!isConfirmed) return

            await ApiKeyService.deleteApiKey({ id: apiKey })

            this.expanded = false
            this.apiKey = ''
        },

        async handleChange({ value }, { apiKey }) {
            await ApiKeyService.updateApiKey({ id: apiKey, data: { active: value } })
        },

        track(action, name) {
            this.$gtag.event(action, {
                event_category: name,
                event_label: this.permissions.level
            })
        }
    }
}
</script>

<style lang="scss">
.api-view {
    .expand-component__inner {
        .button-component:not(:last-child) {
            margin-right: 15px;
        }
    }

    .trash-component {
        margin-left: 15px;
    }

    > .card-component {
        .card-component__header {
            padding: 10px 20px;
        }

        .card-component__footer {
            padding: 15px 20px;
        }
    }

    .card-component--actions {
        .card-component__content {
            padding: 8px;
        }
    }

    p {
        margin-bottom: 20px;
    }

    strong {
        font-weight: 500;
    }

    .tag-component {
        margin-left: 10px;
    }

    .input-component__prepend {
        display: flex;

        justify-content: center;
        align-items: center;

        margin: 0 0 2px 12px;
    }

    .input-component__icon--key {
        @include icon('~@/assets/svg/icons/key.svg');

        display: inline-block;

        width: 16px;
        height: 16px;

        background-size: contain;
    }

    .button-component__icon--book {
        @include icon('~@/assets/svg/icons/book.svg');
    }

    .button-component__icon--book-open {
        @include icon('~@/assets/svg/icons/book-open.svg');
    }

    .button-component--add {
        display: flex;

        justify-content: space-between;

        width: 100%;

        padding: 11px 16px;

        border-radius: 4px;

        &:after {
            content: '';

            flex: 1;
        }
    }

    .button-component--add__icon {
        @include icon('~@/assets/svg/icons/plus.svg');

        display: inline-block;

        flex: 1;

        width: 20px;
        height: 20px;

        background-position: left center !important;
        background-size: contain;
    }
}
</style>
